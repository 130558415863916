import "./src/styles/main.scss";
import * as ReactDOM from 'react-dom/client'; // ✅ this is required


export const onClientEntry = () => {
    document.documentElement.classList.remove('no-js');
  };

  export const replaceHydrateFunction = () => {
    return (element, container) => {
      const root = ReactDOM.createRoot(container);
      root.render(element);
    };
  };